import React, { useState, useEffect } from "react";
import {
  DotMenuIcon,
  TrashIcon,
  EyeIcon2,
  EditIcon3,
} from "../../../assets/Icons";
import { BASE_URL, IMAGE_URL } from "../../../Api/constants";
import { routes } from "../../../Api/routes";
import { postRequest } from "../../../Api";
import { toast } from "react-toastify";
import DataTable from "../../../components/DataTable";
import { capitalizeFirstLetter, getDateString } from "../../../utils/functions";
import AppLoader from "../../../components/Loader/AppLoader";
import Modal from "../../../components/Modal";
import AddPostModel from "../../../components/AddPostModal";
import RejectedReasonModel from "../../../components/RejectedReasonModel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dropdown, Menu, Button, Typography, Image } from "antd";
import AddBalanceOpenModel from "./addbalancepopup";

const AddBalance = ({
  loadingState = () => { },
  data,
  id,
  refreshData = () => { },
  loading = () =>{}
}) => {
  const words = useSelector((state) => state.authReducer.words);
  const navigate = useNavigate();
  const isAdmin = JSON.parse(localStorage.getItem("admin"));





  const columns = [
    {
      title: words["No"],
      dataIndex: "id",
      key: "id",
    },
   
    {
      title: words["Date"],
      dataIndex: "Date",
      key: "Date",
      render: (_, record) => (
        <div
        className="px-2 py-2 text-left text-sm text-black font-medium"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >{getDateString(record.created_at)} </div>
      ),
    },
  
    {
      title: words["Amount"],
      dataIndex: "amount",
      key: "amount",
    },
    

    // {
    //   title: words["Action"],
    //   key: "action",
    //   render: (_, record) => (
    //     <Dropdown
    //       overlay={
    //         <Menu
    //           onClick={({ key }) => {
    //             if (key === "view") {
    //               navigate("/dashboard/order-details/" + record.id);
    //               return;
    //             }
    //             doChangePostStatus(key, record);
    //           }}
    //         >
    //           <Menu.Item key="view">{words["View"]}</Menu.Item>
    //           <Menu.Item key="delete">{words["Delete"]}</Menu.Item>
    //         </Menu>
    //       }
    //       trigger={["click"]}
    //     >
    //       <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
    //         <DotMenuIcon />
    //       </div>
    //     </Dropdown>
    //   ),
    // },
  ];

  return (
    <>


      <div className="flex flex-col w-full gap-4">


        <AddBalanceOpenModel
          id={id}
          loading={loading}
        />

        <div style={{ marginTop: -50 }}>
          <DataTable
            searchContainerStyle={{ width: "40%" }}
            data={data}
            columns={columns}
          />
        </div>

       

      </div>
    </>
  );
};

export default AddBalance;
