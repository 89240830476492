import React, { useState, useEffect } from "react";
import {
  DotMenuIcon,
  TrashIcon,
  EyeIcon2,
  EditIcon3,
} from "../../../assets/Icons";
import SearchBar from "../../../components/SearchBar";
import { BASE_URL, IMAGE_URL } from "../../../Api/constants";
import { routes } from "../../../Api/routes";
import { postRequest } from "../../../Api";
import { toast } from "react-toastify";
import DataTable from "../../../components/DataTable";
import { capitalizeFirstLetter, getDateString } from "../../../utils/functions";
import AppLoader from "../../../components/Loader/AppLoader";
import Modal from "../../../components/Modal";
import AddPostModel from "../../../components/AddPostModal";
import RejectedReasonModel from "../../../components/RejectedReasonModel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dropdown, Menu, Button, Typography, Image } from "antd";

const CompleteOrderTable = ({
  loadingState = () => {},
  data,
  refreshData = () => {},
}) => {
  const words = useSelector((state) => state.authReducer.words);
  const navigate = useNavigate();
  const isAdmin = JSON.parse(localStorage.getItem("admin"));
  const [rejectedAd, setRejectedAd] = useState();
  const [rejectedAdModel, setRejectedAdModel] = useState(false);

  const columns = [
    {
      title: words["Order ID"],
      dataIndex: "id",
      key: "title",
    },
    {
      title: words["Merchant Name"],
      dataIndex: "merchant_name",
      key: "merchantName",
    },
    {
      title: words["Customer  Name"],
      dataIndex: "cust_name",
      key: "cust_name",
    },

    {
      title: words["Date"],
      dataIndex: "date",
      key: "date",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {getDateString(record.created_at)}
        </div>
      ),
    },
    {
      title: words["From"],
      dataIndex: "merchant_location",
      key: "merchant_location",
    },
    {
      title: words["To"],
      dataIndex: "cust_location",
      key: "cust_location",
    },

    {
      title: words["Order Type"],
      dataIndex: "order_type",
      key: "order_type",
    },

    {
      title: words["Delivery Boy"],
      key: "deliveryBoy",
      render: (_, record) => (
        <>
          <div
            className="px-2 py-2 text-left text-sm text-black font-medium"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Image
              src={IMAGE_URL + record.rider?.image}
              style={{ width: 30, height: 30, borderRadius: 5 }}
            />
            {record.rider?.username}
          </div>
        </>
      ),
    },

    {
      title: words["Action"],
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => {
                if (key == "view") {
                  navigate("/dashboard/order-details/" + record.id);
                  // alert('asd');
                  return;
                }
                doChangePostStatus(key, record);
              }}
            >
              <>
                <Menu.Item key="view">{words["View"]}</Menu.Item>
                <Menu.Item key="delete">{words["Delete"]}</Menu.Item>
              </>
            </Menu>
          }
          trigger={["click"]}
        >
          {/* <Button> */}
          <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
            <DotMenuIcon />
          </div>
          {/* </Button> */}
        </Dropdown>
      ),
    },
  ];

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const doChangePostStatus = async (status, record, reason = null) => {
    setRejectedAdModel(false);
    const onSuccess = (res) => {
      // setLoading(false);
      loadingState(false);
      refreshData();
      toast.success("Post status updated!");

      // getDashboard();
    };

    const onError = (err) => {
      // setLoading(false);
      toast.error(
        typeof err == "string"
          ? err
          : "Something went wrong! Please try again later"
      );
      loadingState(false);
      setLoading(false);
      toast.error(err?.error);
    };

    const body = {
      status: status,
      id: record.id,
    };
    if (status == "rejected") body.rejected_reason = reason;
    // setLoading(true);
    loadingState(true);
    await postRequest(body, routes.updatePostStatus, true, onSuccess, onError);
  };

  return (
    <>
      {loading && <AppLoader />}

      <div className="flex flex-col w-full gap-4">
        <div>
          <DataTable
            searchContainerStyle={{ width: "40%" }}
            data={data}
            columns={columns}
          />
        </div>

        <Modal open={openModal} setOpen={setOpenModal}>
          <AddPostModel
            onSuccessCreate={() => refreshData()}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        </Modal>

        <Modal open={rejectedAdModel} setOpen={setRejectedAdModel}>
          <RejectedReasonModel
            openModal={rejectedAdModel}
            setOpenModal={setRejectedAdModel}
            onSubmit={(reason) =>
              doChangePostStatus("rejected", rejectedAd, reason)
            }
          />
        </Modal>
      </div>
    </>
  );
};

export default CompleteOrderTable;
