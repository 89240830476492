import React, { useEffect, useState } from "react";
import { Card, Divider, Image } from "antd"; // Removed Flex import
import { useSelector } from "react-redux";
import { Box, Container, Grid, Icon, Typography } from "@mui/material"; // Use Box and Typography for layout and text styling
import { Icons } from "../../assets/svgs/svg"; // Ensure Icons are correctly imported
import SearchBar from "../../components/SearchBar";
import { Layout } from "../../components";
import { useParams } from "react-router-dom";
import { getRequest } from "../../Api";
import { routes } from "../../Api/routes";
import { toast } from "react-toastify";
import { capitalizeFirstLetter, getDateString, getTimeString } from "../../utils/functions";
import { IMAGE_URL } from "../../Api/constants";
import { GalleryIcon } from "../../assets/Icons";
import { Mail, Phone } from "@mui/icons-material";

const OrderDetails = () => {
  const words = useSelector((state) => state.authReducer.words);
  const { id } = useParams();
  const [details, setDetails] = useState({});


  const getDetails = async () => {

    const onSuccess = (res) => {
      console.log('res', res)
      setDetails(res.data);
    }
    const onError = (err) => {
      toast.error(err?.error)
    }

    await getRequest({}, routes.getOrderDetails + id, true, onSuccess, onError)

  }


  useEffect(() => {
    getDetails();
  }, [])
  const labelsStyle = {
    display: 'flex',
    fontFamily: "IMe",
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: "25.2px",
    letterSpacing: "0.01em",
    textAlign: "left",
    marginLeft: "10px",

  };

  const labelValueStyle = {
    fontFamily: "IRe",
    color: 'rgb(0,0,0,0.7)',
    marginLeft: 5
  }

  const TitlesStyle = {
    fontFamily: "IMe",
    fontSize: "23px",
    fontWeight: 600,
    lineHeight: "32.2px",
    letterSpacing: "0.01em",
    textAlign: "left",
  };

  const Seperator = () => {
    return <Divider style={{ margin: "10px 0", backgroundColor: "#8E8E8F" }} />;
  };


  return (
    <Layout pageTitle={""} >
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography style={TitlesStyle}>Order details</Typography>
              <Card
                bordered={true}
                style={{
                  gap: "0px",
                  border: "0.5px solid ",
                  opacity: 1,
                  borderRadius: "15px",
                  marginTop: "15px",
                  padding: "20px",
                  overflow: "auto",
                }}
              >
                <Box display="flex" alignItems="center">
                  <Icons.custIcon />
                  <Typography style={labelsStyle}>Customer Name:<div style={labelValueStyle}> {details.cust_name}</div> </Typography>
                </Box>
                <Seperator />

                <Box display="flex" alignItems="center">
                  <Icons.cusIconCal />
                  <Typography style={labelsStyle}>Date Order:<div style={labelValueStyle}> {getDateString(details.created_at)}</div> </Typography>
                </Box>
                <Seperator />

                <Box display="flex" alignItems="center">
                  <Icons.cusIconSq />
                  <Typography style={labelsStyle}>Order Type:<div style={labelValueStyle}> {details.order_type}</div> </Typography>
                </Box>
                <Seperator />

                <Box display="flex" alignItems="center">
                  <Icons.cusIconloc />
                  <Typography style={labelsStyle}>Customer Location:<div style={labelValueStyle}> {details.cust_location}</div> </Typography>
                </Box>
                <Seperator />



                <Box display="flex" alignItems="center">
                  <Icons.cusIconTime />
                  <Typography style={labelsStyle}>Time: <div style={labelValueStyle}> {getTimeString(details.right_time)} </div></Typography>
                </Box>
                <Seperator />
                <Box display="flex" alignItems="center">
                  <Icons.cusIconTime />
                  <Typography style={labelsStyle}>Order status: <div style={labelValueStyle}> {capitalizeFirstLetter(details.order_status)} </div></Typography>
                </Box>
                <Seperator />
                {
                  details.cancelled_reason &&
                  <>
                    <Box display="flex" alignItems="center">
                      <Icons.cusMerchantNotes />
                      <Typography style={{ ...labelsStyle, color: 'red' }}>Cancelled Reason: <div style={labelValueStyle}> {capitalizeFirstLetter(details.cancelled_reason)} </div></Typography>
                    </Box>
                    <Seperator />
                  </>
                }
                {
                  details.rejected_reason &&
                  <>
                    <Box display="flex" alignItems="center">
                      <Icons.cusMerchantNotes />
                      <Typography style={{ ...labelsStyle, color: 'red' }}>Rejected Reason: <div style={labelValueStyle}> {capitalizeFirstLetter(details.rejected_reason)} </div></Typography>
                    </Box>
                    <Seperator />
                  </>
                }
                <Box display="flex" alignItems="center">
                  <GalleryIcon width={30} height={30} />
                  <Typography style={labelsStyle}>Order Images :</Typography>
                </Box>
                <Box display="flex" alignItems="center" style={{ marginTop: 10, marginLeft: -10 }}>
                  {
                    details?.order_images?.length ?
                      details?.order_images.map((v, i) => {
                        return (
                          <Image
                            style={{
                              opacity: 1,
                              borderRadius: "10px",
                              width: 100,
                              height: 100,
                              marginLeft: 10
                            }}
                            key={v.id}
                            src={IMAGE_URL + v.image}
                          />
                        )
                      })
                      : null
                  }
                  {/* <Card
                    bordered={true}
                    style={{
                      gap: "2px",
                      border: "0.5px solid ",
                      opacity: 1,
                      borderRadius: "15px",
                      marginTop: "15px",
                      padding: "20px",
                    }}
                  >
                    <Icons.cusImageIcon />
                  </Card>
                  <Card
                    bordered={true}
                    style={{
                      gap: "2px",
                      border: "0.5px solid ",
                      opacity: 1,
                      borderRadius: "15px",
                      marginTop: "15px",
                      padding: "20px",
                      marginLeft: "10px",
                    }}
                  >
                    <Icons.cusImageIcon />
                  </Card>
                  <Card
                    bordered={true}
                    style={{
                      gap: "2px",
                      border: "0.5px solid ",
                      opacity: 1,
                      borderRadius: "15px",
                      marginTop: "15px",
                      padding: "20px",
                      marginLeft: "10px",
                    }}
                  >
                    <Icons.cusImageIcon />
                  </Card> */}
                </Box>

              </Card>

              {
                details?.rider?.id &&

                <Box mt={3}>
                  <Typography style={TitlesStyle}>Rider Information</Typography>
                  <Card
                    bordered={true}
                    style={{
                      gap: "0px",
                      border: "0.5px solid ",
                      opacity: 1,
                      borderRadius: "15px",
                      marginTop: "15px",
                      padding: "20px",
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Image
                        src={IMAGE_URL + details.rider.image}
                        style={{ width: 100, height: 100, borderRadius: 10, marginBottom: 10 }}
                      />
                    </div>
                    <Box display="flex" alignItems="center">
                      <Icons.cusMerchant />
                      <Typography style={labelsStyle}>Username: <div style={labelValueStyle}> {details?.rider.username}</div></Typography>
                    </Box>
                    <Seperator />

                    <Box display="flex" alignItems="center">
                      <Mail />
                      <Typography style={labelsStyle}>Email: <div style={labelValueStyle}> {details?.rider.email}</div></Typography>
                    </Box>
                    <Seperator />
                    <Box display="flex" alignItems="center">
                      <Phone />
                      <Typography style={labelsStyle}>Phone: <div style={labelValueStyle}> {details?.rider.phone}</div></Typography>
                    </Box>
                  </Card>
                </Box>
              }


            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <Typography style={TitlesStyle}>Merchant</Typography>
              <Card
                bordered={true}
                style={{
                  gap: "0px",
                  border: "0.5px solid ",
                  opacity: 1,
                  borderRadius: "15px",
                  marginTop: "15px",
                  padding: "20px",
                }}
              >
                <Box display="flex" alignItems="center">
                  <Icons.cusMerchant />
                  <Typography style={labelsStyle}>Merchant Name: <div style={labelValueStyle}> {details?.merchant_name}</div></Typography>
                </Box>
                <Seperator />

                <Box display="flex" alignItems="center">
                  <Icons.cusMerchantOrder />
                  <Typography style={labelsStyle}>Merchant location: <div style={labelValueStyle}> {details?.merchant_location}</div></Typography>
                </Box>
                <Seperator />

                {/* <Box display="flex" alignItems="center">
                  <Icons.cusMerchantWhatsapp />
                  <Typography style={labelsStyle}>WhatsApp : <div style={labelValueStyle</div></}>Typography>
                </Box>
                <Seperator /> 
                <Box display="flex" alignItems="center">
                  <Icons.cusMerchantTime />
                  <Typography style={labelsStyle}>Time : <div style={labelValueStyle</div></}>Typography>
                </Box>
                <Seperator />
                */}
                <Box display="flex" alignItems="center">
                  <Icons.cusMerchantNotes />
                  <Typography style={labelsStyle}>Note: <div style={labelValueStyle}> {details?.order_note}</div></Typography>
                </Box>
              </Card>
            </Box>

            <Box mt={3}>
              <Typography style={TitlesStyle}>Price Details</Typography>
              <Card
                bordered={true}
                style={{
                  gap: "0px",
                  border: "0.5px solid ",
                  opacity: 1,
                  borderRadius: "15px",
                  marginTop: "15px",
                  padding: "20px",
                }}
              >
                <Box display="flex" alignItems="center">
                  <Icons.cusPriceDelivery />
                  <Typography style={labelsStyle}>Delivery Price:<div style={labelValueStyle}> {details?.delivery_price}</div></Typography>
                </Box>
                <Seperator />

                <Box display="flex" alignItems="center">
                  <Icons.cusPriceApp />
                  <Typography style={labelsStyle}>App commission:<div style={labelValueStyle}> {details?.app_commission}</div></Typography>
                </Box>
                <Seperator />

                <Box display="flex" alignItems="center">
                  <Icons.cusTotalPrices />
                  <Typography style={labelsStyle}>Total Price:<div style={labelValueStyle}> {details?.total_price}</div></Typography>
                </Box>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default OrderDetails;
