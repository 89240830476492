export const en = {
  "Latest posts": "Latest posts",

  No: "No",
  Image: "Image",
  Date: "Date",
  Category: "Category",
  Publisher: "Publisher",
  Status: "Status",
  "Reject orders": "Reject orders",
  Merchant: "Merchant ",
  Delivery: "Delivery ",
  Orders: "Orders",
  Income: "Income",
  News: "News",
  "Ali ahamd": "Ali ahamd",
  No: "No",
  Image: "Image",
  Date: "Date",
  Category: "Category",
  ID: "ID",
  Publisher: "Publisher",
  Status: "Status",
  Action: "Action",
  Reject: "Reject",
  Published: "Published",
  "In-Review": "In-Review",
  ServiceDetails: "ServiceDetails",
  "Basic Details": "Basic Details",
  Images: "Images",
  "Already Registered": "Already Registered",
  "User Register": "User Register",
  "Service Details": "Service Details",
  "Basic Details": "Basic Details",
  Accessory: "Accessory",
  Images: "Images",
  No: "No",
  "Order ID": "Order ID",
  "Merchant Name": "Merchant Name",
  "Customer  Name": "Customer  Name",
  "Order Type": "Order Type",
  "Delivery Boy": "Delivery Boy",
  Date: "Date",
  From: "From",
  To: "To",
  Status: "Status",
  Action: "Action",
  Logo: "Logo",
  "Favicon Logo": "Favicon Logo",
  "Half Logo": "Half Logo",
  "Admin Logos": "Admin Logos",
  Logo: "Logo",
  "Returned Orders": "Returned Orders",
  "Favicon Logo": "Favicon Logo",
  "Half Logo": "Half Logo",
  "Company Address": "Company Address",
  "Company Short Description": "Company Short Description",
  "Copyright Details": "Copyright Details",
  "Support Hours": "Support Hours",
  "Customer Terms and Conditions": "Customer Terms and Conditions",
  Save: "Save",
  "Customer privacy policy": "Customer privacy policy",
  Save: "Save",
  Add: "Add",
  Delete: "Delete",

  Images: "Images",
  Photos: "Photos",
  off: "off",
  on: "on",

  City: "City",
  Name: "Name",

  "ID Card": "ID Card",
  View: "View",
  Edit: "Edit",
  Delete: "Delete",
  No: "No",

  Location: "Location",
  Status: "Status",
  Action: "Action",
  Link: "Link",
  Edit: "Edit",
  Delete: "Delete",
  No: "No",
  "Store Name": "Store Name",

  Status: "Status",
  Action: "Action",

  Logo: "Logo",
  "Logo.png": "Logo.png",

  View: "View",
  Edit: "Edit",
  Delete: "Delete",

  No: "No",

  Location: "Location",
  Status: "Status",
  Action: "Action",
  Stores: "Stores",
  Interval: "Interval",
  Price: "Price",
  "Stat Date": "Stat Date ",
  "End Date": "End Date",
  Status: "Status",
  Action: "Action",
  Name: "Name",
  "Phone Number": "Phone Number",
  "User type": "User type",
  Save: "Save",
  Edit: "Edit",
  Delete: "Delete",
  Home: "Home",
  "Completed Orders": "Completed Orders",
  Ads: "Ads",
  Users: "Users",
  Finance: "Finance",
  Banner: "Banner",
  Notification: "Notification",
  Staff: "Staff",
  Setting: "Setting",
  "Underway Orders": "Underway Orders",
  Add: "Add",
  "Add Post": "Add Post",
  url: "url",
  "Start date": "Start date",
  "End Date": "End Date",
  "Created at": "Created at",
  "End date": "End date",
  "Total amount": "Total amount",

  "Payment status": "Payment status",
  Description: "Description",
  Type: "Type",
  "Publishing Add": "Publishing Add",
  "User type": "User type",
  id: "id",
  Duration: "Duration",
  "Ad Id": "Ad Id",
  "User name": "User name",
  "Transaction date": "Transaction date",
  "User phone": "User phone",
  "Send Notification": "Send Notification",
  Dashboard: "Dashboard",
  "Add Staff": "Add Staff",
  Title: "Title",

  Careers: "Careers",
  Orders: "Orders",
  "His Majesty and his family": "His Majesty and his family",
  Decrees: "Decrees",
  "Omani projects": "Omani projects",
  "Places in Oman": "Places in Oman",
  "Disaster awareness": "Disaster awareness",
  "Omani history": "Omani history",
  "The brave men of Oman": "The brave men of Oman",
  "Oman Vision 2040": "Oman Vision 2040",
  "Sports in Oman": "Sports in Oman",
  "Omani personalities": "Omani personalities",
  "The Palestinian cause": "The Palestinian cause",

  "وظائف ": "Careers",
  "اخبار ": "News",
  "صور المقام السامي و عائلته ": "His Majesty and his family",
  "مراسيم ": "Decrees",
  "مشاريع عمانية": "Omani projects",
  "أماكن في عمان ": "Places in Oman",
  "التوعية عند الكوارث ": "Disaster awareness",
  "التاريخ العماني": "Omani history",
  "رجال عمان الاشاوس ": "The brave men of Oman",
  "رؤية عمان ٢٠٤٠": "Oman Vision 2040",
  "الرياضة في عمان ": "Sports in Oman",
  "شخصيات عمانية": "Omani personalities",
  "القضية الفلسطينية": "The Palestinian cause",

  Food: "Food",
  Hotels: "Hotels",
  Discounts: "Discounts",
  Properties: "Properties",
  مطاعم: "Food",
  فنادق: "Hotels",
  تخفيضات: "Discounts",
  اماكن: "Properties",

  Tax: "Tax",
  "Select category": "Select category",
  "Ad information": "Ad information",
  "Ad video or image": "Ad video or image",
  "Image or Video": "Image or Video",
  "Posted by": "Posted by",
  "1 Month": "1 Month",
  "3 Months": "3 Months",
  "6 Months": "6 Months",
  "1 Year": "1 Year",
  Pending: "Pending",
  Rejected: "Rejected",
  Approved: "Approved",
  Paid: "Paid",
  Unpaid: "Unpaid",
  "Ad details": "Ad details",
  Approve: "Approve",
  "Rejected Reason": "Rejected Reason",
  "Enter rejected reason": "Enter rejected reason",
  "Rejected reason": "Rejected reason",
  Reject: "Reject",
  "Add Image & Video": "Add Image & Video",

  "Enter post title": "Enter post title",
  "Enter Post description": "Enter Post description",
  rejected: "rejected",
  published: "published",
  "in-review": "in-review",
  "Post details": "Post details",
  "Publishing Posts": "Publishing Posts",
  Phone: "Phone",
  Id: "Id",
  "Are you sure you want to delete the record ?":
    "Are you sure you want to delete the record ?",
  Cancel: "Cancel",
  Yes: "Yes",
  Active: "Active",
  Inactive: "Inactive",
  "Transaction id": "Transaction id",
  "Start/End date": "Start/End date",

  "Enter description": "Enter description",
  Published: "Published",
  Rejected: "Rejected",
  "In-review": "In-review",
  "View Ad": "View Ad",
  "Enter post url": "Enter post url",
  "Edit Banner": "Edit Banner",
  "Add Banner": "Add Banner",
  "Company Whatsapp": "Company Whatsapp",
  "Company X": "Company X",
  "Company Instagram": "Company Instagram",
  "Terms & Privacy Settings": "Terms & Privacy Settings",
  "Company Settings": "Company Settings",
  "Company Title": "Company Title",
  "Support Name": "Support Name",
  "Support Email": "Support Email",
  Mobile: "Mobile",
  "Select Time Zone": "Select Time Zone",
  "Max Serviceable Distance (in Kms)": " Max Serviceable Distance (in Kms)",
  "Country Code": "Country Code",
  "Primary Color": "Primary Color",
  "Secondary Color": "Secondary Color",
  "Primary Shadow Color": "Primary Shadow Color",
  "OTP System": "OTP System",
  "Login Image": "Login Image",
  "Latest posts": "Latest posts",
  "Add user": "Add user",
  Password: "Password",
  "Enter Phone": "Enter Phone",
  "Enter password": "Enter password",
  "Enter Name": "Enter Name",
  Email: "Email",
  Update: "Update",
  Profile: "Profile",

  "Ad settings": "Ad settings",
  "Price/Month": "Price/Month",
  "Price/3 Months": "Price/3 Months",
  "Price/6 Months": "Price/6 Months",
  "Price/Year": "Price/Year",

  //  FOR THE USERS SCREEN
  Merchants: "Merchants",
  "Delivery Boys": "Delivery Boys",
  No: "  No",
  Image: "Image",
  ID: "ID",
  Name: "Name",
  Phone: "Phone",
  Email: "Email",
  "Delivered Order": "Delivered Orders",
  "Returned Orders": "Returned Orders",
  Status: "Status",
  Action: "Action",

  //  FOR THE USERS-Merchand SCREEN ( Mostly Same If you want to remove then remove it :-)
  No: "No",
  Image: "Image",
  ID: "ID",
  Name: "Name",
  Phone: "Phone",
  Email: "Email",
  City: "City",
  
  Status: "Status",
  Action: "Action",
  ActionsUser: "ActionsUser",
  actionuser: "actionuser",
  "Underway Order": "Underway Order",
  underwayorder: "underwayorder",
  "Complete Order": "Complete Order",
  completeorder: "completeorder",
  "Add Balance": "Add Balance ",
  addbalance: "addbalance",
  "Returned Order": "Returned Order",
  returnedorder: "returnedorder",
  "Referral Code": "Referral Code",
  referralcode: "referralcode",
  "Expiry Date": "Expiry Date",
  Amount: "Amount",
};
