import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import { Dropdown, Menu, Button, Typography } from "antd";

import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";

import Header from "../../components/Header";
import Modal from "../../components/Modal";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import {
  DotMenuIcon,
  NotesIcon,
  IncomeIcon,
  UsersIcon,
  ChartIcon,
  TrashIcon,
  EyeIcon2,
} from "../../assets/Icons";
import NewsDetailsModel from "../../components/NewsDetailsModel";
import { postRequest } from "../../Api";
import { routes } from "../../Api/routes";
import { capitalizeFirstLetter, getDateString } from "../../utils/functions";
import { BASE_URL, IMAGE_URL } from "../../Api/constants";
import {
  useApproveAdMutation,
  useGetAdsQuery,
  useRejectAdMutation,
} from "../../store/services/adsService";
import DataTable from "../../components/DataTable";
import { useSelector } from "react-redux";
import { Image } from "antd";

const Main = () => {
  // const words = useSelector((state)=>state.au)

  const words = useSelector((state) => state.authReducer.words);
  const isAdmin = JSON.parse(localStorage.getItem("admin"));
  const navigate = useNavigate();

  const { data: ads, isLoading } = useGetAdsQuery();
  const [
    approveAd,
    {
      isLoading: adApproveLoading,
      isSuccess: approveAdSuccess,
      isError: approveError,
    },
  ] = useApproveAdMutation();
  const [
    rejectAd,
    {
      isLoading: adRejectLoading,
      isSuccess: rejectAdSuccess,
      isError: rejectError,
    },
  ] = useRejectAdMutation();

  const [openModal, setOpenModal] = useState(false);
  const [latestPost, setLatestPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [counts, setCounts] = useState({
    users: "",
    posts: "",
    ad: "",
    income: "",
  });

  const storeData = [
    {
      storeName: "Ali ahamd",
      phone: "+968 96669730",
      email: "Ahmad29@gmail.com",
      idStore: "#U3123",
      location: "Seeb,Muscat",
      publishing: "7",
      status: "Published",
    },
    {
      storeName: "Ali ahamd",
      phone: "+968 96669730",
      email: "Ahmad29@gmail.com",
      idStore: "#U3123",
      location: "Seeb,Muscat",
      publishing: "7",
      status: "Published",
    },
    {
      storeName: "Ali ahamd",
      phone: "+968 96669730",
      email: "Ahmad29@gmail.com",
      idStore: "#U3123",
      location: "Seeb,Muscat",
      publishing: "7",
      status: "Published",
    },
    {
      storeName: "Ali ahamd",
      phone: "+968 96669730",
      email: "Ahmad29@gmail.com",
      idStore: "#U3123",
      location: "Seeb,Muscat",
      publishing: "7",
      status: "Published",
    },
  ];

  const columns = [
    {
      title: words["id"],
      dataIndex: "id",
      key: "id",
    },
    // {
    //   title: words["Image"],
    //   dataIndex: "image",
    //   key: "image",
    //   render: (_, record) => (
    //     <div className="flex items-center">
    //       <Image
    //         style={{ width: 70, height: 70 }}
    //         src={IMAGE_URL + record.image}
    //         alt={record.image}
    //         className="h-14 w-24 object-cover bg-gray-300 rounded-lg"
    //       />
    //     </div>
    //   ),
    // },
    {
      title: words["Title"],
      dataIndex: "title",
      key: "title",
    },
    // {
    //   title: words["Description"],
    //   dataIndex: "description",
    //   key: "description",
    // },
    {
      title: words["Category"],
      dataIndex: "ad_category",
      key: "ad_category",
    },
    // {
    //   title: words["Duration"],
    //   dataIndex: "ad_duration",
    //   key: "ad_duration",
    // },

    // {
    //   title: words["Start date"],
    //   dataIndex: "start_date",
    //   key: "start_date",
    //   render: (_, record) => (
    //     <div
    //       className="px-2 py-2 text-left text-sm text-black font-medium"
    //       style={{
    //         display: "flex",
    //         flexDirection: "row",
    //         alignItems: "center",
    //       }}
    //     >
    //       {getDateString(record.start_date)}
    //     </div>
    //   ),
    // },

    // {
    //   title: words["End date"],
    //   dataIndex: "end_date",
    //   key: "end_date",
    //   render: (_, record) => (
    //     <div
    //       className="px-2 py-2 text-left text-sm text-black font-medium"
    //       style={{
    //         display: "flex",
    //         flexDirection: "row",
    //         alignItems: "center",
    //       }}
    //     >
    //       {getDateString(record.end_date)}
    //     </div>
    //   ),
    // },

    {
      title: words["Total amount"],
      dataIndex: "total_amount",
      key: "total_amount",
    },
    // {
    //   title: words["Tax"],
    //   dataIndex: "tax",
    //   key: "tax",
    // },
    {
      title: words["Payment status"],
      dataIndex: "payment_status",
      key: "payment_status",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {capitalizeFirstLetter(record.payment_status)}
        </div>
      ),
    },

    {
      title: words["Status"],
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <div
          // className="px-2 py-2 text-left text-sm text-black font-medium"
          className={`py-2 px-0 rounded-full text-center font-semibold ${record.status?.toLowerCase() === "approved"
              ? "bg-[#0DB214]/40 text-[#0DB214]"
              : "bg-red-200 text-red-400"
            }`}
          style={
            {
              // display: "flex",
              // flexDirection: "row",
              // alignItems: "center",
            }
          }
        >
          {words[capitalizeFirstLetter(record.status)]}
        </div>
      ),
    },

    {
      title: words["Action"],
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => {
                if (key == "view") {
                  navigate("/dashboard/ad-details/" + record.id);
                  return;
                }
              }}
            >
              <>
                <Menu.Item key="view">{words["View"]}</Menu.Item>
              </>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button>
            <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
              <DotMenuIcon />
            </div>
          </Button>
        </Dropdown>
      ),
    },

    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (_, record) => (
    //     <Dropdown
    //       overlay={
    //         <Menu
    //           onClick={({ key }) => {
    //             if (key == 'approve') {
    //               doApproveAd(record.id)
    //               // setEditModalItem(record)
    //               // setEditModal(true);
    //             }
    //             if (key == 'reject') {
    //               doRejectAd(record.id)
    //               // setDeletedId(record.id);
    //               // setOpenDeleteModal(true);
    //             }
    //           }}
    //         >
    //           <>
    //             <Menu.Item key="approve">Approve</Menu.Item>
    //             <Menu.Item key="reject">Reject</Menu.Item>
    //           </>

    //         </Menu>
    //       }
    //       trigger={['click']}
    //     >
    //       <Button>
    //         <div
    //           className="flex items-center justify-center cursor-pointer text-[#0025F2]"
    //         >
    //           <DotMenuIcon />
    //         </div>
    //       </Button>
    //     </Dropdown>
    //   ),

    // },
  ];

  const postsColumns = [
    {
      title: words["id"],
      dataIndex: "id",
      key: "id",
    },
    {
      title: words["Title"],
      dataIndex: "title",
      key: "title",
    },
    {
      title: words["Category"],
      dataIndex: "category",
      key: "category",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {words[record.category]}
        </div>
      ),
    },
    {
      title: words["Status"],
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <div
          className={`py-2 px-0 rounded-full text-center font-semibold ${record.status?.toLowerCase() === "published"
              ? "bg-[#0DB214]/40 text-[#0DB214]"
              : "bg-red-200 text-red-400"
            }`}
        >
          {words[capitalizeFirstLetter(record.status)]}
        </div>
      ),
    },
    {
      title: words["Created at"],
      dataIndex: "created_at",
      key: "created_at",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {getDateString(record.created_at)}
        </div>
      ),
    },
    {
      title: words["Action"],
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => {
                if (key == "view") {
                  navigate("/dashboard/post-details/" + record.id);
                  return;
                }
              }}
            >
              <>
                <Menu.Item key="view">{words["View"]}</Menu.Item>
              </>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button>
            <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
              <DotMenuIcon />
            </div>
          </Button>
        </Dropdown>
      ),
    },
  ];

  const getDashboard = async () => {
    const onSuccess = (res) => {
      setLoading(false);
      setCounts({
        ...counts,
        users: res?.data.users_count,
        posts: res?.data.posts_count,
        ad: res?.data.ad_count,
        income: res?.data.income,
      });
      const postsData = res.data.latest_posts ?? [];
      setLatestPosts(postsData?.length ? postsData.slice(0, 10) : []);
    };

    const onError = (err) => {
      setLoading(false);
      toast.error(err?.error);
    };

    setLoading(true);
    await postRequest("", routes.dashboard, true, onSuccess, onError);
  };

  const doUpdatePostStatus = async (status, id) => {
    const onSuccess = (res) => {
      getDashboard();
    };

    const onError = (err) => {
      setLoading(false);
      toast.error(err?.error);
    };

    setLoading(true);
    const body = {
      status: status,
      id: id,
    };
    await postRequest(body, routes.updatePostStatus, true, onSuccess, onError);
  };

  const doApproveAd = (id) => {
    approveAd(id);
  };

  const doRejectAd = (id) => {
    rejectAd(id);
  };

  useEffect(() => {
    getDashboard();
  }, []);

  const TableRow = ({ item, index }) => {
    const [hide, setHide] = useState(false);
    useEffect(() => {
      document.body.addEventListener("click", () => {
        document.body.style.overflowY = "auto";
        setHide(false);
      });
    }, []);
    return (
      <tr
        key={index}
        className={`p-2 ${index % 2 === 0 ? "bg-white" : "bg-white"}`}
      >
        <td className="px-2 py-2 text-sm text-black font-medium">
          {index + 1}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          <div className="flex items-center">
            <Image
              src={IMAGE_URL + item.image}
              style={{ width: 70, height: 70 }}
              // "/images/post-img.png"
              alt="userImage"
              className="h-14 w-24 object-cover bg-gray-300 rounded-lg"
            />
            {/* <img
              src={IMAGE_URL + item.image}
              // "/images/post-img.png"
              alt="userImage"
              className="h-14 w-24 object-cover bg-gray-300 rounded-lg"
            /> */}
          </div>
        </td>
        <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
          {item.title}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          {getDateString(item.created_at)}
          {/* 21/11/2023 */}
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          Business
        </td>
        {/* <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          #A1425
        </td> */}
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          <div className="flex items-center gap-2">
            {/* <img
              src="/images/user1.png"
              alt="userImage"
              className="h-8 w-8 rounded-full object-contain bg-gray-300"
            /> */}
            {"Admin"}
          </div>
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
          <div
            className={`py-2 px-0 rounded-full text-center font-semibold ${item.status?.toLowerCase() === "published"
                ? "bg-[#0DB214]/40 text-[#0DB214]"
                : "bg-red-200 text-red-400"
              }`}
          >
            {capitalizeFirstLetter(item.status)}
          </div>
        </td>
        {/* <td className="px-2 py-2 whitespace-nowrap text-xs text-[#0025F2] relative">
          <div
            className="flex items-center justify-center cursor-pointer text-[#0025F2]"
            onClick={(e) => {
              e.stopPropagation();
              setHide(!hide);
            }}
          >
            <DotMenuIcon />
          </div>
          <div
            className={`menuList-block flex items-center absolute ${hide ? "show" : ""
              }`}
          >
            <div className="menu-item flex items-center flex-col anim">
              <div
                // to={`/`}
                className="slt flex items-center gap-1 justify-between text-white text-sm"
                onClick={(e) => {
                  setHide(!hide);
                  doUpdatePostStatus('rejected', item.id);
                }}
              >
                Reject
              </div>
              <div
                className="slt flex items-center gap-1 justify-between text-white text-sm"
                onClick={(e) => {
                  setHide(!hide);
                  doUpdatePostStatus('published', item.id);
                }}
              >
                Publish
              </div>
              {
                item.status == 'rejected' || item.status == 'published' ?
                  <div
                    className="slt flex items-center gap-1 justify-between text-white text-sm"
                    onClick={(e) => {
                      setHide(!hide);
                      doUpdatePostStatus('in-review', item.id);
                    }}
                  >
                    In-Review
                  </div>
                  : null
              }
            </div>
          </div>
        </td> */}
      </tr>
    );
  };

  // const OrdersTableRow = ({ item, index }) => {
  //   const options = [
  //     { value: "Review", label: "Review" },
  //     { value: "Publishing", label: "Publishing" },
  //     { value: "Cancel", label: "Cancel" },
  //   ];
  //   const [selectedStatus, setSelectedStatus] = useState();
  //   const [hide, setHide] = useState(false);
  //   useEffect(() => {
  //     document.body.addEventListener("click", () => {
  //       document.body.style.overflowY = "auto";
  //       setHide(false);
  //     });
  //   }, []);
  //   return (
  //     <tr
  //       key={index}
  //       className={`p-2 ${index % 2 === 0 ? "bg-white" : "bg-white"}`}
  //     >
  //       <td className="px-2 py-2 text-sm text-black font-medium">
  //         {index + 1}
  //       </td>
  //       <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
  //         <div className="flex items-center">
  //           <img
  //             src="/images/post-img.png"
  //             alt="userImage"
  //             className="h-14 w-24 object-cover bg-gray-300 rounded-lg"
  //           />
  //         </div>
  //       </td>
  //       <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
  //         {item.phone}
  //       </td>
  //       <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
  //         21/11/2023
  //       </td>
  //       <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
  //         Business
  //       </td>
  //       <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
  //         #A1425
  //       </td>
  //       <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
  //         <div className="flex items-center gap-2">
  //           <img
  //             src="/images/user1.png"
  //             alt="userImage"
  //             className="h-8 w-8 rounded-full object-contain bg-gray-300"
  //           />
  //           {item.storeName}
  //         </div>
  //       </td>
  //       <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
  //         <Select
  //           defaultValue={selectedStatus}
  //           value={selectedStatus}
  //           onChange={setSelectedStatus}
  //           options={options}
  //           placeholder="Choose"
  //           className="min-w-[120px]"
  //         />
  //         {/* <div
  //           className={`py-2 px-2 rounded-full text-center font-semibold ${
  //             item.status === "Active"
  //               ? "bg-[#0DB214]/40 text-[#0DB214]"
  //               : "bg-red-200 text-red-400"
  //           }`}
  //         >
  //           {item.status}
  //         </div> */}
  //       </td>
  //       <td className="px-2 py-2 whitespace-nowrap text-xs text-[#0025F2] relative">
  //         <div
  //           className="flex items-center justify-center cursor-pointer text-[#0025F2]"
  //           onClick={(e) => {
  //             e.stopPropagation();
  //             setHide(!hide);
  //           }}
  //         >
  //           <DotMenuIcon />
  //         </div>
  //         <div
  //           className={`menuList-block flex items-center absolute !bg-transparent !border-none !shadow-none ${hide ? "show" : ""
  //             }`}
  //         >
  //           <div className="menu-item flex items-center flex-col anim !gap-2">
  //             <div
  //               className="slt flex items-center justify-center !h-8 !w-8 rounded-full bg-[#6DBCC3] !p-0 !border-none"
  //               onClick={(e) => {
  //                 setHide(!hide);
  //                 setOpenModal(true);
  //               }}
  //             >
  //               <EyeIcon2 />
  //             </div>
  //             <div
  //               className="slt flex items-center justify-center !h-8 !w-8 rounded-full bg-[#FF5A5D] !p-0"
  //               onClick={(e) => {
  //                 setHide(!hide);
  //               }}
  //             >
  //               <TrashIcon />
  //             </div>
  //           </div>
  //         </div>
  //       </td>
  //     </tr>
  //   );
  // };

  return (
    <Layout>
      {loading || adApproveLoading || adRejectLoading ? (
        <div className="wrap wrapWidth flex flex-col items-center ">
          <Loader />
        </div>
      ) : (
        <div className="lading-page h-screen flex">
          <div className="wrap wrapWidth flex flex-col">
            <div className="home-grid w-full grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4 mb-8">
              <div className="grid-box gap-3 flex border border-[#D8D8D8] p-4 rounded-2xl">
                <div className="icon flex items-center justify-center bg-[#47A000]/20 h-16 w-16 rounded-2xl">
                  <UsersIcon />
                </div>
                <div className="flex flex-col gap-2 py-2">
                  <p className="text-[#696974] text-xs">{words["Merchant"]}</p>
                  <h2 className="text-[#171725] text-xl font-medium">
                    {counts.users}
                  </h2>
                </div>
              </div>
              <div className="grid-box gap-3 flex border border-[#D8D8D8] p-4 rounded-2xl">
                <div className="icon flex items-center justify-center bg-[#0066A0]/20 h-16 w-16 rounded-2xl">
                  <UsersIcon />
                </div>
                <div className="flex flex-col gap-2 py-2">
                  <p className="text-[#696974] text-xs">{words["Delivery"]}</p>
                  <h2 className="text-[#171725] text-xl font-medium">
                    {counts.posts}
                  </h2>
                </div>
              </div>
              <div className="grid-box gap-3 flex border border-[#D8D8D8] p-4 rounded-2xl">
                <div className="icon flex items-center justify-center bg-[#A0009A]/20 h-16 w-16 rounded-2xl">
                  {/* <div className="text-[#A0009A]"> */}
                  <ChartIcon />
                  {/* </div> */}
                </div>
                <div className="flex flex-col gap-2 py-2">
                  <p className="text-[#696974] text-xs">{words["Orders"]}</p>
                  <h2 className="text-[#171725] text-xl font-medium">
                    {counts.ad}
                  </h2>
                </div>
              </div>
              <div className="grid-box gap-3 flex border border-[#D8D8D8] p-4 rounded-2xl">
                <div className="icon flex items-center justify-center bg-[#F21531]/20 h-16 w-16 rounded-2xl">
                  <div className="text-[#F21531]">
                    <IncomeIcon />
                  </div>
                </div>
                <div className="flex flex-col gap-2 py-2">
                  <p className="text-[#696974] text-xs">{words["Income"]}</p>
                  <h2 className="text-[#171725] text-xl font-medium">
                    {counts.income}
                  </h2>
                </div>
              </div>
            </div>

            {/* <div>
              <h1 className="text-[#18120F] text-2xl font-medium border-l-8 border-themeColor px-2">
                {words["Latest posts"]}
              </h1>
              <DataTable
                searchContainerStyle={{ width: "40%" }}
                data={latestPost}
                columns={postsColumns}
              />
            </div> */}

            {/* <div className="flex flex-col gap-2 mb-8">
              <h1 className="text-[#18120F] text-2xl font-medium border-l-8 border-themeColor px-2">
                {words["Latest posts"]}
              </h1>
              <div className="responsive-table overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-[#F5F7FB]">
                    <tr>
                      <th className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider">
                        {words["No"]}
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        {words["Image"]}
                      </th>
                      <th className="px-2 py-2 text-left  w-56 text-sm text-black font-medium">
                        {words["Title"]}
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        {words["Date"]}
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        {words["Category"]}
                      </th>
                      
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        {words["Publisher"]}
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        {words["Status"]}
                      </th>
                    
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {latestPost?.map((item, index) => (
                      <TableRow item={item} index={index} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div> */}

            {/* <div className="flex flex-col gap-2">
              <h1 className="text-[#18120F] text-2xl font-medium border-l-8 border-themeColor px-2">
                {words["Latest orders"]}
              </h1>
              <div>
                <DataTable
                  searchContainerStyle={{ width: "40%" }}
                  data={ads?.data ?? []}
                  columns={columns}
                />
              </div>
            </div> */}
          </div>
          <Modal open={openModal} setOpen={setOpenModal}>
            <NewsDetailsModel
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
          </Modal>
        </div>
      )}
    </Layout>
  );
};

export default Main;
