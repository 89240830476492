import { configureStore } from "@reduxjs/toolkit";

import authService from "./services/authService";

import authReducer from "./reducers/authReducer";
import globalReducer from "./reducers/globalReducer";
import adsServices from "./services/adsService";
import notificationService from "./services/notificationService";
import staffServices from "./services/staffService";
import settingService from "./services/settingService";
import orderServices from "./services/orderService";
import userService from "./services/userService";

const Store = configureStore({
  reducer: {
    [authService.reducerPath]: authService.reducer,
    [adsServices.reducerPath]: adsServices.reducer,
    [notificationService.reducerPath]: notificationService.reducer,
    [staffServices.reducerPath]: staffServices.reducer,
    [settingService.reducerPath]: settingService.reducer,
    [orderServices.reducerPath]: orderServices.reducer,
    [userService.reducerPath]: userService.reducer,

    authReducer: authReducer,
    globalReducer: globalReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
    adsServices.middleware,
    notificationService.middleware,
    staffServices.middleware,
    settingService.middleware,
    orderServices.middleware,
    userService.middleware
  ]),
});

export default Store;
