import React from "react";

function GalleryIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 40 40"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M15 16.667A3.333 3.333 0 1015 10a3.333 3.333 0 000 6.667z"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M21.667 3.333H15C6.667 3.333 3.333 6.667 3.333 15v10c0 8.333 3.334 11.667 11.667 11.667h10c8.333 0 11.667-3.334 11.667-11.667v-8.333"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="3"
        d="M26.25 8.333h9.167M30.833 12.917V3.75"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M4.45 31.583l8.217-5.516c1.316-.884 3.216-.784 4.4.233l.55.483c1.3 1.117 3.4 1.117 4.7 0l6.933-5.95c1.3-1.116 3.4-1.116 4.7 0l2.717 2.334"
      ></path>
    </svg>
  );
}

export default GalleryIcon;
