import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../Api/constants";

const orderServices = createApi({
  reducerPath: "order",
  tagTypes: "order",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = localStorage.getItem('admin-token')
      // reducers?.authReducer?.token;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),

  endpoints: (builder) => {

    return {
      getOrders: builder.query({
        query: () => {
          return {
            url: "/api/admin/getOrders",
            method: "GET",
          };
        },
        providesTags: ["order"],
      }),

      addStaff: builder.mutation({
        query: (data) => {
          return {
            url: "/api/admin/staff",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["order"],
      }),

      deleteStaff: builder.mutation({
        query: (id) => {
          return {
            url: "/api/admin/staff/" + id,
            method: "DELETE"
          };
        },
        invalidatesTags: ["order"],
      }),


    };
  },
});
export const { useAddStaffMutation, useDeleteStaffMutation, useGetOrdersQuery } = orderServices;
export default orderServices;
