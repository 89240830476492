export const routes = {
    login: 'admin/login',
    dashboard: 'admin/dashboard',
    getOrderDetails: 'admin/getOrderDetails/',
    getUsers: 'admin/get_users',
    getUser: 'admin/get_user',
    editUser: 'admin/edit_user',
    addUser: 'admin/add_user',
    deleteUser: 'admin/delete_user',


    getBanners: 'admin/get_banners',
    addBanner: 'admin/add_banner',
    editBanner: 'admin/edit_banner/',
    deleteBanner: 'admin/delete_banner',
    getSettings: 'admin/settings',
    addSettings: 'admin/addSettings',
    getProfile: 'admin/profile',
    updateProfile: 'admin/update_profile',
    addBalance: 'admin/addRiderBalance',



}