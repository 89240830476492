import React, { useState, useEffect } from "react";
import {
  DotMenuIcon,
  TrashIcon,
  EyeIcon2,
  EditIcon3,
} from "../../../assets/Icons";
import SearchBar from "../../../components/SearchBar";
import { BASE_URL, IMAGE_URL } from "../../../Api/constants";
import { routes } from "../../../Api/routes";
import { postRequest } from "../../../Api";
import { toast } from "react-toastify";
import DataTable from "../../../components/DataTable";
import { capitalizeFirstLetter, getDateString } from "../../../utils/functions";
import AppLoader from "../../../components/Loader/AppLoader";
import Modal from "../../../components/Modal";
import AddPostModel from "../../../components/AddPostModal";
import RejectedReasonModel from "../../../components/RejectedReasonModel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dropdown, Menu, Button, Typography, Image } from "antd";

const Merchants = ({
  loadingState = () => { },
  data,
  refreshData = () => { },
}) => {
  const words = useSelector((state) => state.authReducer.words);
  const navigate = useNavigate();
  const isAdmin = JSON.parse(localStorage.getItem("admin"));
  const [rejectedAd, setRejectedAd] = useState();
  const [rejectedAdModel, setRejectedAdModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const columns = [
    {
      title: words["Id"],
      dataIndex: "id",
      key: "id",
    },
    {
      title: words["Image"],
      dataIndex: "image",
      key: "image",
      render: (_, record) => (
        <div className="flex items-center justify-center">
          <Image
            src={IMAGE_URL + record.image}
            alt={record.cust_name}
            style={{ width: 50, height: 50, borderRadius: "50%" }}
          />
        </div>
      ),
    },
    {
      title: words["Name"],
      dataIndex: "username",
      key: "username",

    },
    {
      title: words["Phone"],
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: words["Email"],
      dataIndex: "email",
      key: "email",
    },
    {
      title: words["Delivered Order"],
      dataIndex: "delivered_orders_count",
      key: "delivered_orders_count",
      render: (_, record) => (
        <div
          style={{
            border: "1px solid #0DB214",
            padding: "10px",
            borderRadius: "5px",
            maxWidth: 60,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          {record.delivered_orders_count}
        </div>
      ),
    },

    {
      title: words["Returned Orders"],
      key: "returned_orders_count",
      render: (_, record) => (
        <div
          style={{
            border: "1px solid #F09308",
            padding: "10px",
            borderRadius: "5px",
            maxWidth: 60,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          {record.returned_orders_count}
        </div>
      ),
    },
    {
      title: words["Underway Orders"],
      key: "underway_orders_count",
      render: (_, record) => (
        <div
          style={{
            border: "1px solid #F09308",
            padding: "5px",
            borderRadius: "5px",
            maxWidth: 60,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          {record.underway_orders_count}
        </div>
      ),
    },
    // {
    //   title: words["Status"],
    //   dataIndex: "merchant_location",
    //   key: "merchant_location",
    // },
    {
      title: words["Action"],
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => {
                if (key === "view") {
                  navigate("/dashboard/user-details/" + record.id);
                  return;
                }
              }}
            >
              <Menu.Item key="view">{words["View"]}</Menu.Item>
              {/* <Menu.Item key="delete">{words["Delete"]}</Menu.Item> */}
            </Menu>
          }
          trigger={["click"]}
        >
          <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
            <DotMenuIcon />
          </div>
        </Dropdown>
      ),
    },
  ];

  const doChangePostStatus = async (status, record, reason = null) => {
    setRejectedAdModel(false);
    const onSuccess = (res) => {
      // setLoading(false);
      loadingState(false);
      refreshData();
      toast.success("Post status updated!");

      // getDashboard();
    };

    const onError = (err) => {
      // setLoading(false);
      toast.error(
        typeof err == "string"
          ? err
          : "Something went wrong! Please try again later"
      );
      loadingState(false);
      setLoading(false);
      toast.error(err?.error);
    };

    const body = {
      status: status,
      id: record.id,
    };
    if (status == "rejected") body.rejected_reason = reason;
    // setLoading(true);
    loadingState(true);
    await postRequest(body, routes.updatePostStatus, true, onSuccess, onError);
  };

  return (
    <>
      {loading && <AppLoader />}

      <div className="flex flex-col w-full gap-4">
        <div>
          <DataTable
            searchContainerStyle={{ width: "40%" }}
            data={data}
            columns={columns}
          />
        </div>

        <Modal open={openModal} setOpen={setOpenModal}>
          <AddPostModel
            onSuccessCreate={() => refreshData()}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        </Modal>

        <Modal open={rejectedAdModel} setOpen={setRejectedAdModel}>
          <RejectedReasonModel
            openModal={rejectedAdModel}
            setOpenModal={setRejectedAdModel}
            onSubmit={(reason) =>
              doChangePostStatus("rejected", rejectedAd, reason)
            }
          />
        </Modal>
      </div>
    </>
  );
};

export default Merchants;
