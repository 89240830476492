export const ar = {
  "Latest posts": "Latest posts",

  No: "رقم",
  Image: "صورة",
  Title: "عنوان",
  Date: "تاريخ",
  Category: "المواضيع",
  Publisher: "منشور",
  Status: "الحالة",
  "Latest orders": "الطلبات الاخيرة",
  Merchant: "مستخدم",
  Post: "منشور",
  AD: "اعلان",
  Income: "الدخل",
  News: "الاخبار",
  No: "رقم",
  Image: "صورة",
  Title: "عنوان",
  Date: "تاريخ",
  Category: "المواضيع",
  ID: "رمز",
  Publisher: "منشور",
  Status: "الحالة",
  Action: "تعديل",
  Reject: "مرفوض",
  Published: "منشور",
  "In-Review": "يتم المراجعة",

  View: "عرض",
  Edit: "تعديل",
  Delete: "حذف",

  Interval: "Interval",
  Price: "مبلغ",
  "Stat Date": "تاريخ البدء",
  "End Date": "تاريخ الانتهاء",
  Status: "الحالة",
  Action: "تعديل",
  Name: "الاسم",
  Save: "حفظ",
  Edit: "تعديل",
  Delete: "حذف",
  Home: "الرئيسية",
  Posts: "المنشورات",
  Ads: "الاعلانات",
  Users: "المستخدمين",
  Finance: "المالية",
  Banner: "الشرائح",
  Notification: "الاشعارات",
  Staff: "الموظفين",
  Setting: "الاعدادات",
  "Approval requests": "طلبات الموافقة",
  Posts: "المنشورات",
  Add: "اضافة",
  "Add Post": "اضافة منشور",
  url: "الرابط",
  "Start date": "تاريخ البدء",
  "End Date": "تاريخ الانتهاء",
  "Created at": "تم الانشاء في",
  "End date": "تاريخ الانتهاء",
  "Total amount": "المبلغ الاجمالي",
  "Payment status": "حالة الدفع",
  Description: "الوصف",
  Type: "النوع",
  "Publishing Add": "إضافة النشر",
  "User type": "نوع المستخدم",
  id: "الرمز",
  Duration: "المدة",
  "Ad Id": "رمز الاعلان",
  "User name": "اسم المستخدم",
  "Transaction date": "تاريخ التحويل",
  "User phone": "رقم الهاتف",
  "Send Notification": "ارسال اشعار",
  Dashboard: "لوحة التحكم",
  "Add Staff": "اضافة موظف",

  Careers: "وظائف",
  News: "الاخبار",
  "His Majesty and his family": "المقام السامي وعائلته",
  Decrees: "مراسيم",
  "Omani projects": "مشاريع عمانية",
  "Places in Oman": "أماكن في عمان",
  "Disaster awareness": "التوعية عند الكوارث",
  "Omani history": "التاريخ العماني",
  "The brave men of Oman": "رجال عمان الاشاوس",
  "Oman Vision 2040": "رؤية عمان ٢٠٤٠",
  "Sports in Oman": "الرياضة في عمان",
  "Omani personalities": "شخصيات عمانية",
  "The Palestinian cause": "القضية اللسطينية",

  "وظائف ": "Careers",
  "اخبار ": "News",
  "صور المقام السامي و عائلته ": "His Majesty and his family",
  "مراسيم ": "Decrees",
  "مشاريع عمانية": "Omani projects",
  "أماكن في عمان ": "Places in Oman",
  "التوعية عند الكوارث ": "Disaster awareness",
  "التاريخ العماني": "Omani history",
  "رجال عمان الاشاوس ": "The brave men of Oman",
  "رؤية عمان ٢٠٤٠": "Oman Vision 2040",
  "الرياضة في عمان ": "Sports in Oman",
  "شخصيات عمانية": "Omani personalities",
  "القضية الفلسطينية": "The Palestinian cause",

  Food: "مطاعم",
  Hotels: "فنادق",
  Discounts: "تخفيضات",
  Properties: "اماكن",

  مطاعم: "Food",
  فنادق: "Hotels",
  تخفيضات: "Discounts",
  اماكن: "Properties",

  Tax: "ضريبة",
  "Select category": "اختيار الموضوع",
  "Ad information": "معلومات الاعلان",
  "Ad video or image": "اضف صورة او فيديو",
  "Image or Video": "صورة او فيديو",
  "Posted by": "نشر من قبل",
  "1 Month": "شهر",
  "3 Months": "٣ شهور",
  "6 Months": "٦ شهور",
  "1 Year": "سنه",
  Pending: "معلق",
  Rejected: "مرفوض",
  Approved: "موافقة",
  Paid: "مدفوع",
  Unpaid: "غير مدفوع",
  "Ad details": "بيانات الاعلان",
  Approve: "موافقة",
  "Rejected Reason": "سبب الرفض",
  "Enter rejected reason": "ادخل سبب الرفض",
  "Rejected reason": "سبب الرفض",
  Reject: "رفض",
  "Add Image & Video": "اضف صورة & فديو",

  "Enter post title": "عنوان المنشور",
  "Enter Post description": "محتوى المنشور",
  rejected: "مرفوض",
  published: "تم النشر",
  "in-review": "قيد المراجعة",
  "Post details": "تفاصيل المنشور ",
  "Publishing Posts": "الاخبار المنشورة",
  Phone: "هاتف",
  Id: "تعريف",
  "Are you sure you want to delete the record ?":
    "متاكد بانك تريد حذف هذه التسجيل?",
  Cancel: "الغاء",
  Yes: "نعم",
  Active: "تفعيل",
  Inactive: "غير مفعل",
  "Transaction id": "رمز التحويل",
  "Start/End date": "تاريخ البدء/الانتهاء",

  "Enter description": "أدخل الوصف",
  Published: "نشرت",
  Rejected: "مرفوض",
  "In-review": "في مراجعة",
  "View Ad": "عرض الإعلان",
  "Enter post url": "أدخل عنوان URL للنشر",
  "Edit Banner": "تحرير لافتة",
  "Add Banner": "إضافة لافتة",
  "Company Whatsapp": "شركة واتس اب",
  "Company X": "الشركة X",
  "Company Instagram": "انستغرام الشركة",
  "Terms & Privacy Settings": "الشروط وإعدادات الخصوصية",
  "Company Settings": "إعدادات الشركة",
  "Company Title": "عنوان الشركة",
  "Support Name": "اسم الدعم",
  "Support Email": "البريد الإلكتروني للدعم",
  Mobile: "متحرك",
  "Select Time Zone": "اختر المجال الزمني",
  "Max Serviceable Distance (in Kms)": "أقصى مسافة للخدمة (بالكيلومترات)",
  "Country Code": "الرقم الدولي",
  "Primary Color": "لون أصلي",
  "Secondary Color": "اللون الثانوي",
  "Primary Shadow Color": "لون الظل الأساسي",
  "OTP System": "نظام مكتب المدعي العام",
  "Login Image": "صورة تسجيل الدخول",
  "Latest posts": "آخر المشاركات",
  "Add user": "إضافة مستخدم",
  Password: "كلمة المرور",
  "Enter Phone": "أدخل الهاتف",
  "Enter password": "أدخل كلمة المرور",
  "Enter Name": "أدخل الاسم",
  Email: "بريد إلكتروني",
  Update: "تحديث",
  Profile: "حساب تعريفي",
  "Ad settings": "إعدادات الإعلان",
  "Price/Month": "السعر/الشهر",
  "Price/3 Months": "السعر/3 أشهر",
  "Price/6 Months": "السعر/6 أشهر",
  "Price/Year": "السعر/السنة",
};
