// export const IMAGE_URL = "http://127.0.0.1:8000/";
// export const BASE_URL = "http://127.0.0.1:8000";
// export const API_URL = "http://127.0.0.1:8000/api/";

// export const IMAGE_URL = "http://192.168.100.21:8000/";
// export const BASE_URL = "http://192.168.100.21:8000";
// export const API_URL = "http://192.168.100.21:8000/api/";

//

export const API_URL = 'https://mandoob.etmaan.org/backend/public/api/';
export const BASE_URL = 'https://mandoob.etmaan.org/backend/public';
export const IMAGE_URL = 'https://mandoob.etmaan.org/backend/public';
