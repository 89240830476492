/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./css/App.scss";
import "./index.css";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";

import Main from "./Pages/Home";
import Routing from "./routes/Routing";
import { useSelector } from "react-redux";
import InvoiceDetails from "./Pages/Finance/InvoiceDetails";
import logo_1 from './assets/images/logo_1.svg'

function App() {
  const lang = useSelector((state) => state.authReducer.lang);
  // const [openSidebar, setOpenSidebar] = useState(false);
  // var activeUser = localStorage.getItem("_user");






  return (
    <div dir={lang == "en" ? "ltr" : "rtl"}>
      <Routing />
    </div>

  );
}

export default App;
