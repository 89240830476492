import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Layout from "../../../components/Layout"; // Import your custom Layout component
import UnderwayOrderTable from "../../Orders/subtabs/underwayOrderTable";
import AppLoader from "../../../components/Loader/AppLoader";
import { Icons } from "../../../assets/svgs/svg";
import { useSelector } from "react-redux";

import { Divider, Image } from "antd";
import AddBalance from "./addbalance";
import { useParams } from "react-router-dom";
import { useGetRiderDetailsQuery } from "../../../store/services/userService";
import { IMAGE_URL } from "../../../Api/constants";
import RiderOrderTypesTable from "../../Orders/subtabs/RiderOrderTypesTable";
import { Balance, CopyAllOutlined, EmailOutlined } from "@mui/icons-material";


const riderInfoContainers = {
  backgroundColor: '#DDF6F5', padding: '2px 7px', display: 'flex', alignItems: 'center', borderRadius: 10, marginLeft: 5
}

const riderInfoText = {
  color: '#9499A1', fontFamily: 'IRe', fontSize: 12, marginLeft: 5
}



const UserAction = () => {
  const words = useSelector((state) => state.authReducer.words);
  const isAdmin = JSON.parse(localStorage.getItem("admin"));

  const { id } = useParams();
  // Assuming the user data is available in your Redux store
  const user = useSelector((state) => state.authReducer.user);

  const {
    data: data,
    isLoading,
    isSuccess,
    isError,
  } = useGetRiderDetailsQuery(id);

  const [underwayOrder, setUnderwayOrder] = useState([]);
  const [addbalance, setAddBalance] = useState([]);
  const [riderDetails, setRiderDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [riderTransactions, setRiderTransactions] = useState([]);
  const tabs = [
    {
      label: words["Underway Order"],
      value: "underwayorder",
    },
    {
      label: words["Complete Order"],
      value: "completeorder",
    },
    {
      label: words["Returned Order"],
      value: "returnedorder",
    },
    {
      label: words["Add Balance"],
      value: "addbalance",
    },
    {
      label: words["Referral Code"],
      value: "referralcode",
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].value);


  async function copyToClipBoard(text) {
    await navigator.clipboard.writeText(text);

  }


  useEffect(() => {
    if (data?.status == '1') {
      setRiderDetails(data?.data?.rider)
      setRiderTransactions(data?.data?.transactions);
    }
  }, [data]);

  return (
    <>
      {loading && <AppLoader />}

      <Layout pageTitle={words["Users"]}>
        <Divider
          style={{ borderColor: "#9C8579", width: "130px", opacity: 0.5 }}
        />
        <div
          style={{
            marginBottom: "20px",
            position: 'relative'
          }}
        >
          <div style={{ position: "absolute", }}>
            <Icons.cusBackgroundUser />
          </div>

          <div
            style={{
              zIndex: 1,
              marginLeft: 30,
              display: 'flex',

            }}
          >
            <Image

              // IMAGE_URL + riderDetails?.image ||
              src={"https://via.placeholder.com/150"}
              alt="Rider Profile"
              style={{
                width: "140px",
                height: "140px",
                border: "2px solid white",
                marginTop: 15,
                borderRadius: 10
              }}
            />
            <div style={{ marginLeft: 10, marginTop: 87 }}>
              <div style={{ color: '#3D475C', fontSize: 16, fontFamily: 'IBo', letterSpacing: 0.8 }}>{riderDetails?.username}</div>
              <div style={{ display: 'flex', marginTop: 5 }}>
                <div style={riderInfoContainers}>
                  <EmailOutlined style={{ fontSize: 14, color: '#9499A1' }} />
                  <div style={riderInfoText}>{riderDetails?.email}</div>
                </div>
                <div
                  onClick={() => copyToClipBoard(riderDetails?.phone)}
                  style={{ ...riderInfoContainers, cursor: 'pointer' }}>
                  <CopyAllOutlined style={{ fontSize: 14, color: '#9499A1' }} />
                  <div style={riderInfoText}>{riderDetails?.phone}</div>
                </div>
                <div style={riderInfoContainers}>
                  <Balance style={{ fontSize: 14, color: '#9499A1' }} />
                  <div style={riderInfoText}>{riderDetails?.balance}</div>
                </div>
                {
                  riderDetails?.referral_code &&
                  <div style={riderInfoContainers}>
                    <div style={riderInfoText}>{riderDetails?.referral_code}</div>
                  </div>
                }
              </div>
            </div>
          </div>


        </div>
        <br />
        <br />
        <div>
          <div className="store-page min-h-screen flex">
            <div className="wrap wrapWidth flex flex-col">
              {isAdmin?.type === "admin" ? (
                <div className="flex flex-col gap-5 mt-6">
                  <div className="flex w-full border-b-[1px] border-b-themeColor border-transparent rounded">
                    {tabs.map((item, index) => (
                      <button
                        key={index}
                        className={`btn font-normal rounded ${activeTab === item.value
                          ? "bg-[#2EBFB9] text-white"
                          : "bg-transparent text-[#2EBFB9]"
                          }`}
                        onClick={() => setActiveTab(item.value)}
                      >
                        {item.label}
                      </button>
                    ))}
                  </div>




                  {activeTab === "addbalance" ? (
                    <AddBalance
                      loading={(x) =>  setLoading(x)}
                      data={riderTransactions}
                      id={riderDetails?.id}
                    />
                  ) :
                    <RiderOrderTypesTable

                      data={activeTab == 'underwayorder' ? riderDetails?.underway_orders : activeTab == 'returnedorder' ? riderDetails?.returned_orders : activeTab == 'completeorder' ? riderDetails?.delivered_orders : []} />}
                </div>
              ) : (
                <UnderwayOrderTable
                  refreshData={""} data={""} />
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default UserAction;
