import React from 'react';





function getDateString(date) {
  try {
    date = new Date(date);
    let day = '';
    const year = date.getFullYear();
    let month = '';
    if (date.getMonth() + 1 < 10) {
      month = '0' + (date.getMonth() + 1);
    } else {
      month = date.getMonth() + 1;
    }
    if (date.getDate() < 10) {
      day = '0' + date.getDate();
    } else {
      day = date.getDate();
    }

    const dateStr = month + '-' + day + '-' + year;
    return dateStr;
  } catch (e) {
    console.log(e);
  }
}

function getTimeString(_date) {
  const date = new Date(_date);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}


// TO FORCE THE COMPONENT TO UPDATE, TO GET THE STATE RESULT 
const useForceUpdate = () => {
  const [, updateState] = React.useState();
  return React.useCallback(() => updateState({}), []);
}

//  CAPATILIAZE THE FIRST LETTER OF STRING
const capitalizeFirstLetter = (str) => {
  if (str) {
    return str?.charAt(0)?.toUpperCase() + str.slice(1);
  }
  return null

};


// VALIDATING EMAIL
function validateEmail(text) {
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (reg.test(text) === false) return false;
  else return true;
}



function generateRandomKey(uri) {
  let shortUrl = uri.substring(uri.length - 4, uri.length - 10);
  const randomNum = Math.floor(Math.random() * Math.pow(10, 15));
  const keyString = randomNum.toString().padStart(15, '0') + shortUrl;
  return keyString;
}


function getTimeAgo(date) {
  const MINUTE = 60 * 1000; // milliseconds in a minute
  const HOUR = 60 * MINUTE; // milliseconds in an hour
  const DAY = 24 * HOUR; // milliseconds in a day
  const WEEK = 7 * DAY; // milliseconds in a week
  const MONTH = 30 * DAY; // approximate milliseconds in a month
  const YEAR = 365 * DAY; // approximate milliseconds in a year

  const currentTime = new Date().getTime();
  const pastTime = new Date(date).getTime();
  const timeDiff = currentTime - pastTime;

  if (timeDiff < MINUTE) {
    return 'just now';
  } else if (timeDiff < HOUR) {
    const minutes = Math.floor(timeDiff / MINUTE);
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else if (timeDiff < DAY) {
    const hours = Math.floor(timeDiff / HOUR);
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (timeDiff < WEEK) {
    const days = Math.floor(timeDiff / DAY);
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (timeDiff < MONTH) {
    const weeks = Math.floor(timeDiff / WEEK);
    return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
  } else if (timeDiff < YEAR) {
    const months = Math.floor(timeDiff / MONTH);
    return `${months} month${months > 1 ? 's' : ''} ago`;
  } else {
    const years = Math.floor(timeDiff / YEAR);
    return `${years} year${years > 1 ? 's' : ''} ago`;
  }
}

const formatDocumentFileSize = (sizeInBytes) => {
  const KB = 1024;
  const MB = KB * 1024;

  if (sizeInBytes >= MB) {
    return (sizeInBytes / MB).toFixed(2) + ' MB';
  } else if (sizeInBytes >= KB) {
    return (sizeInBytes / KB).toFixed(2) + ' KB';
  } else {
    return sizeInBytes + ' Bytes';
  }
};

function extractTimeFromJsDate(date) {
  if (!date) return;
  const dateObject = new Date(date);
  const hours = dateObject.getHours().toString().padStart(2, '0');
  const minutes = dateObject.getMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
}

const convertToSQLDate = (htmlDate) => {
  // Assuming the input is in 'YYYY-MM-DD' format
  const date = new Date(htmlDate);
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};

const convertToSQLDateTime = (htmlDate) => {
  // Assuming the input is in 'YYYY-MM-DD' format
  const date = new Date(htmlDate);
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const hours = '00';
  const minutes = '00';
  const seconds = '00';
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const convertSQLDateTimeToHTMLDate = (sqlDateTime) => {
  return sqlDateTime?.split(' ')[0];
};


export {
  generateRandomKey,
  useForceUpdate,
  capitalizeFirstLetter,
  validateEmail,
  getDateString,
  getTimeString,
  getTimeAgo,
  formatDocumentFileSize,
  extractTimeFromJsDate,
  convertToSQLDate,
  convertToSQLDateTime,
  convertSQLDateTimeToHTMLDate,
}